import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Sample } from '@shared/models/sample.model';
import { ChangesPopup } from '../changes/changes.component';

@Component({
  selector: 'app-device-popup',
  templateUrl: 'device-popup.component.html',
  styleUrls: ['device-popup.component.scss'],
})
export class DevicePopup {
  samplesForm = this.fb.group({
    samples: this.fb.array([])
  });
  sampleArray: FormArray = this.samplesForm.get('samples') as FormArray;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DevicePopup>,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.data.device.samples.forEach((s: Sample) => {
      this.sampleArray.push(this.fb.group({
        sample_id: s.sample_id,
        name: s.name,
        type: s.type,
        min: s.min,
        max: s.max,
        unit: s.unit,
        value: this.getValue(s)
      }));
    });
    this.sampleArray.markAllAsTouched();
  }

  private getValue(s: Sample): FormControl {
    switch (s.type) {
      case 'number': return this.fb.control({value: s.value, disabled: !s.editable}, [Validators.min(s.min), Validators.max(s.max), Validators.pattern(/^[0-9]*[.]?[0-9]*$/)]);
      default: return this.fb.control({value: s.value, disabled: !s.editable});
    }
  }

  close(): void { this.dialogRef.close(); }

  submit(): void {
    const changes: Sample[] = [];
    this.sampleArray.controls.forEach((fg: FormGroup) => {
      if (fg.dirty) {
        const sample: Sample = this.data.device.samples.find((s2: Sample) => s2.sample_id === fg.value.sample_id);        
        changes.push({
          sample_id: sample.sample_id,
          name: sample.name,
          value: sample.value,
          newValue: sample.type === 'number' ? Number(fg.value.value) : fg.value.value
        });
      }
    });

    this.dialog.open(ChangesPopup, {
      autoFocus: false,
      panelClass: 'devices-edition',
      data: {
        site_uuid: this.data.site_uuid,
        changes
      }
    }).afterClosed().subscribe((valuesHasBeenSet: boolean) => {
      if (valuesHasBeenSet) this.close();
    });
  }
}
