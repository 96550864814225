import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Panel} from '@models';

@Component({
  selector: 'fs-dashboard',
  templateUrl: 'fs-dashboard.component.html',
  styleUrls: ['./fs-dashboard.component.scss'],
})
export class FsDashboardComponent {
  constructor(
    public dialogRef: MatDialogRef<FsDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) public panel: Panel
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
