import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Activation, Configuration, LastSeen, setSiteConfigs, Site, User } from '@models';
import { AlarmsService, LoginService, SiteService } from '@services';
import { appUrl } from '@shared/URL';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  public numberOfflineSites: number = 0;
  public numberActiveAlarms: number = 0;
  public numberOfSiteWithAlarms: number = 0;
  public sites: Site[] = [];
  public configurations: Configuration[];
  private sortArray: boolean[] = [false, false, false];
  showFooter: boolean = false;

  constructor(
    private siteService: SiteService,
    private loginService: LoginService,
    private router: Router,
    private alarmsService: AlarmsService
  ) {}

  ngOnInit() {
    this.loginService.fetchUser().subscribe((user: User) => {
      if (user) {
        this.showFooter = user.company_uuid !== '65d501ff-e976-49ff-9203-47eb02240bdb';
        forkJoin(
          this.siteService.getSites(user.user_uuid),
          this.alarmsService.getConfigurations(),
          this.alarmsService.getActivations()
        ).subscribe((res: [Site[], Configuration[], Activation[]]) => {
          this.numberOfflineSites = 0;
          this.numberOfSiteWithAlarms = 0;
          this.numberActiveAlarms = res[2].length;
          this.configurations = res[1].map((c: Configuration) => {
            c.activations = res[2].filter((a: Activation) => a.alarm_uuid === c.alarm_uuid);
            return c;
          });
          this.sites = res[0].map((s: Site) => setSiteConfigs(s, this.configurations));
          this.siteService.getLastSeen().subscribe((ls: LastSeen[]) => {
            this.sites.map((s: Site) => this.setSitesWithLastSeen(s, ls));
            this.sortByStatus();
          });
        })
      }
    });
  }

  private setSitesWithLastSeen(s: Site, ls: LastSeen[]): Site {
    const matchLastSeen: LastSeen = ls.find((l: LastSeen) => l.site_uuid === s.site_uuid);
    s.last_seen = matchLastSeen ? matchLastSeen.max : undefined;
    if (!!s.configurations && !!s.configurations.find((c: Configuration) => !!c.activations.find((a: Activation) =>  a.to === undefined))) {
      s.status = 'home.sites.alarm';
      this.numberOfSiteWithAlarms++;
    } else if (s.last_seen && s.last_seen.isAfter(moment().subtract(1, 'hour'))) {
      s.status = 'home.sites.online';
    } else {
      this.numberOfflineSites++;
      s.status = 'home.sites.offline';
    }
    return s;
  }
  
  public sortByStatus() {
    this.sortArray[0] ?
      this.sites.reverse() :
      this.sites.sort((s1: Site, s2: Site) => {
        if (s1.status === 'home.sites.alarm') return -1;
        if (s2.status === 'home.sites.alarm') return 1;
        if (s1.status === 'home.sites.online') return -1;
        if (s2.status === 'home.sites.online') return 1;
        return null;
      });
    this.sortArray = [!this.sortArray[0], false, false];
  }
  
  public sortBySiteName() {
    this.sortArray[1] ?
      this.sites.reverse() :
      this.sites.sort((s1: Site, s2: Site) => {
        const name1 = s1.site_name.toLowerCase(), name2 = s2.site_name.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
      });
    this.sortArray = [false, !this.sortArray[1], false];
  }
  
  public sortByLastSeen() {
    this.sortArray[2] ?
      this.sites.reverse() :
      this.sites.sort((s1: Site, s2: Site) => {
        if (!s1.last_seen) return 1;
        if (!s2.last_seen) return -1;
        if (moment(s1.last_seen).isAfter(s2.last_seen)) return -1;
        if (moment(s1.last_seen).isBefore(s2.last_seen)) return 1;
        return 0;
      });
    this.sortArray = [false, false, !this.sortArray[2]];
  }

  public getColor(status: string): string {
    switch(status) {
      case 'home.sites.alarm': return '#E44432';
      case 'home.sites.online': return '#3E7566';
      case 'home.sites.offline': return '#766F67';
    }
  }

  public hoverOnSite(s: Site, show: boolean) {
    s.hovered = show;
  }

  public goToSite(s: Site) {
    this.router.navigate([appUrl.site], {queryParams: {id: s.site_uuid}});
  }
}
