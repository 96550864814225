import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DeviceService } from '@core/services/device.service';
import { Sample } from '@shared/models/sample.model';

@Component({
  selector: 'app-changes',
  templateUrl: 'changes.component.html',
  styleUrls: ['./changes.component.scss'],
})
export class ChangesPopup {
  errorMessage: string;

  constructor(
    private dialogRef: MatDialogRef<ChangesPopup>,
    private deviceService: DeviceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close(): void { this.dialogRef.close(); }

  submit(): void {
    const samples: Sample[] = this.data.changes.map((s: Sample) => ({sample_id: s.sample_id, value: s.newValue}));
    this.deviceService.setSampleValues(this.data.site_uuid, samples).subscribe(
      (response: any) => {
        if (response.error) this.errorMessage = response.error;
        else {
          if (response.find((s: any) => s.result === 'ERROR')) {
            response.forEach(element => {
              const sample: Sample = this.data.changes.find((s: Sample) => s.sample_id === element.sample_id);
              sample.message = element.message;
              sample.error = element.result === 'ERROR'
            });
          }
          else this.dialogRef.close(true);
        }
      },
      (e: HttpErrorResponse) => this.errorMessage = e.message
    );
  }
}
