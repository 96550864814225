import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from '@core/services/login.service';

@Injectable()
export class AuthenticatedGuard implements CanActivate {

  constructor(
    private loginService: LoginService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.loginService.isUserLogged()) {
      this.loginService.logout(state.url);
      return false;
    }
    return true;
  }
}
