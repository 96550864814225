import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { LoginService } from '@core/services/login.service';
import { appUrl } from '@shared/URL';
import * as whiteLabel from 'assets/whiteLabel/configs.json';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  public logo_url: string = 'assets/custom/elum-logo-noir.png';
  public url: string = this.router.url;
  public email: string;
  public error: string;
  private whiteLabelVersion: string;
  public returnUrl: string;
  public isLogguedIn: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.loginService.isUserLogged()) {
      this.router.navigateByUrl(appUrl.home);
    } else {
      this.isLogguedIn = false;
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || appUrl.home;
      this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((nav: NavigationStart) => {
        if ([appUrl.login, appUrl.reset].includes(nav.url)) this.url = nav.url;
      });
      this.setLogo();
      this.setForm();
    }
  }

  private setLogo() {
    if (location.hostname) {
      const path: string[] = location.hostname.split('.');
      if (path[0] === 'www') path.shift();
      if (
        !path ||
        (!['beta', 'e-lum', 'localhost'].includes(path[0].toLowerCase()) &&
          whiteLabel &&
          whiteLabel.default[path[0]])
      ) {
        this.logo_url = 'assets/custom/' + whiteLabel.default[path[0]].logo_black;
        this.whiteLabelVersion = path[0];
      }
    }
  }

  private setForm() {
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required, Validators.minLength(4)]]
    });
  }

  public login() {
    delete this.error;
    this.loginService.login({
      username: this.form.value.username.toLowerCase(),
      password: this.form.value.password
    }).subscribe(
      () => this.router.navigateByUrl(this.returnUrl || appUrl.home),
      (e: HttpErrorResponse) => this.error = e.error);
  }
}
