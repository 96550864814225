import { Pipe, PipeTransform } from '@angular/core';
import { Activation } from '@shared/models';

@Pipe({name: 'activationFilter'})
export class ActivationFilterPipe implements PipeTransform {
  private sort: string[] = [];

  transform(activations: Activation[], criticities: string[], sortArray: string[]): Activation[] {
    let filterActivations: Activation[] = activations
      .filter((a: Activation) => !criticities.length || criticities.includes(a.criticity));

    if (sortArray[0] || sortArray[1] || sortArray[2] || sortArray[3] || sortArray[4]) {
      filterActivations = activations.sort((a1: Activation, a2: Activation) => {
        if (sortArray[0]) {
          return new Date(a1.from).getTime() - new Date(a2.from).getTime();
        } else if (sortArray[1]) {
          if (!a1.to) return -1;
          if (!a2.to) return 1;
          return new Date(a1.to).getTime() - new Date(a2.to).getTime();
        } else if (sortArray[2]) {
          return a1.device_custom_name.localeCompare(a2.device_custom_name);
        } else if (sortArray[3]) {
          return a1.message.localeCompare(a2.message);
        } else if (sortArray[4]) {
          return a1.criticity.localeCompare(a2.criticity);
        } else if (this.sort[1] || this.sort[2] || this.sort[3] || this.sort[4]) {
          return 0;
        }
      })
    } else if (this.sort[0] || this.sort[1] || this.sort[2] || this.sort[3] || this.sort[4]) {
      filterActivations = activations.reverse();
    }

    this.sort = sortArray;
    return filterActivations;
  }
}
