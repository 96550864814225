export enum EAlarmCriticity {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum EAlarmPeriod {
  seconds = 'seconds',
}

export enum EAlarmAggregation {
  min = 'models.aggregation.min',
  max = 'models.aggregation.max',
  avg = 'models.aggregation.avg',
  first = 'models.aggregation.first',
  last = 'models.aggregation.last',
  count = 'models.aggregation.count',
}

export enum EAlarmComparison {
  'less' = 'models.comparison.less',
  'equal' = 'models.comparison.equal',
  'not_equal' = 'models.comparison.not_equal',
  'greater' = 'models.comparison.greater',
}

export enum EHttpMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export enum EChannelType {
  timeserie = 'timeserie',
  persistent = 'persistent',
}
