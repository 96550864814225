import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Activation, Channel, Configuration, Site } from '@models';
import { apiUrl } from '@shared/URL';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Injectable()
export class AlarmsService {
  site: Site;
  from: string = moment().subtract(1, 'week').startOf('day').toISOString();
  to: string = moment().endOf('day').toISOString();

  private httpOptions = (site_uuid: string) => {
    return {
      params: new HttpParams()
      .set('site_uuid', site_uuid)
      .set('from', moment(this.from).startOf('day').toISOString())
      .set('to', moment(this.to).endOf('day').toISOString())
    };
  };

  constructor(
    private http: HttpClient
  ) {}

  // Configurations

  getConfigurations(): Observable<Configuration[]> {
    return this.http.get<Configuration[]>(apiUrl.configurations);
  }

  getConfigurationsBySiteUUID(site_uuid: string): Observable<Configuration[]> {
    return this.http.get<Configuration[]>(apiUrl.configurations, {params: new HttpParams().set('site_uuid', site_uuid)});
  }

  putConfiguration(c: Configuration): Observable<Configuration> {
    if (!c.alarm_uuid) c.alarm_uuid = uuid();
    return this.http.put<Configuration>(`${apiUrl.configurations}/${c.alarm_uuid}`, c);
  }

  deleteConfiguration(alarm_uuid: string): Observable<any> {
    return this.http.delete<any>(`${apiUrl.configurations}/${alarm_uuid}`);
  }

  // Mailing list

  isUserInMailingList(alarm_uuid: string, user_uuid: string): Observable<any> {
    return this.http.get(`${apiUrl.configurations}/${alarm_uuid}/${apiUrl.mailing}/${user_uuid}`);
  }

  addUserToMailingList(alarm_uuid: string, user_uuid: string): Observable<any> {
    return this.http.put(`${apiUrl.configurations}/${alarm_uuid}/${apiUrl.mailing}/${user_uuid}`, null, {observe: 'response'});
  }

  removeUserFromMailingList(alarm_uuid: string, user_uuid: string): Observable<any> {
    return this.http.delete(`${apiUrl.configurations}/${alarm_uuid}/${apiUrl.mailing}/${user_uuid}`, {observe: 'response'});
  }

  // Activations

  getActivations(): Observable<Activation[]> {
    return this.http.get<Activation[]>(apiUrl.activations, {
      params: new HttpParams().set('current_only', 'true')
    });
  }

  getActivationsBySiteUUID(site_uuid: string): Observable<Activation[]> {
    return this.http.get<Activation[]>(apiUrl.activations, this.httpOptions(site_uuid));
  }

  deleteActivation(activation_uuid: string): Observable<any> {
    return this.http.delete<any>(`${apiUrl.activations}/${activation_uuid}`);
  }

  // Channels

  getChannels(): Observable<Channel[]> {
    return this.http.get<Channel[]>(apiUrl.channels);
  }

  getChannelsBySiteUUID(site_uuid: string): Observable<Channel[]> {
    return this.http.get<Channel[]>(`${apiUrl.channels}?filter=site_uuid:${site_uuid}`);
  }
}
