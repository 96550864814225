import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoginService } from '@core/services/login.service';
import { User } from '@shared/models';
import { appUrl } from '@shared/URL';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  // Show/Hide headers elements
  public user: User;
  public isLoggedIn: boolean;
  // Sers à checker les URL actives
  public appUrl: Function = appUrl;
  public url: string = '';
  logo: string = '/assets/custom/elum-logo-blanc.png';
  showMenu: boolean = false;

  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(({url}: NavigationEnd) => {
      this.url = url;
      this.isLoggedIn = this.loginService.isUserLogged();
    });

    this.loginService.userArrived.subscribe((user: User) => {
      this.user = user;
      if (user.company_uuid === '65d501ff-e976-49ff-9203-47eb02240bdb') this.logo = '/assets/custom/enviroearth-logo.png';
    });
  }

  public goTo(page: string) {
    this.showMenu = false;
    this.router.navigate([appUrl[page]]);
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  avoidClosing(e: Event) {
    e.stopPropagation();
  }

  public logout() {
    this.loginService.logout();
  }
}
