import { Pipe, PipeTransform } from '@angular/core';
import { EAlarmCriticity } from '../models/enumerations';

@Pipe({name: 'criticityImage'})
export class CriticityImagePipe implements PipeTransform {
  transform(value: string): string {
    let color: string = 'green';
    switch(value) {
      case Object.keys(EAlarmCriticity)[0]: color = 'yellow'; break;
      case Object.keys(EAlarmCriticity)[1]: color = 'orange'; break;
      case Object.keys(EAlarmCriticity)[2]: color = 'red'; break;
    }
    return 'assets/custom/location_' + color + '.png';
  }
}
