import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material';
import { SiteService } from '@core/services';
import { GroupNode, Site, User } from '@shared/models';

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  site_uuid: string;
}

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
})
export class TreeComponent {
  private transformer = (node: GroupNode, level: number): FlatNode => {
    return {
      expandable: node.children && node.children.length > 0,
      name: node.name,
      level,
      site_uuid: node.site_uuid
    };
  }

  treeControl = new FlatTreeControl<FlatNode>(node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  public hasChild = (_: number, node: FlatNode): boolean => node.expandable;
  
  @Input() user: User;
  private _sites: Site[];
  @Input()
  get sites(): Site[] { return this._sites; }
  set sites(sites: Site[]) {
    if (sites) {
      this._sites = sites;
      this.getGroupNode();
    }
  }
  private _site_uuid: string;
  @Input()
  get site_uuid(): string { return this._site_uuid; }
  set site_uuid(site_uuid: string) {
    this._site_uuid = site_uuid;
    this.selectNodeInTree();
  }

  @Output() onSiteSelected: EventEmitter<string> = new EventEmitter();
  public listedSites: Site[];

  constructor(
    private siteService: SiteService
  ) {}

  private selectNodeInTree() {
    if (this.treeControl.dataNodes && this.site_uuid) {
      const rootNode: FlatNode = this.treeControl.dataNodes[0];
      // Node to open in tree
      const targetNode: FlatNode = this.treeControl.dataNodes.find((node: FlatNode) => node.site_uuid === this.site_uuid);
      this.treeControl.expand(rootNode);
      this.treeControl.getDescendants(rootNode).forEach((node: FlatNode) => {
        // For each root's child, expand if it includes the target node
        if (this.treeControl.getDescendants(node).includes(targetNode)) this.treeControl.expand(node)
      })
    }    
  }

  private getGroupNode() {
    this.siteService.getGroupNode(this.user.user_uuid, this.user.group_uuid)
    .subscribe((gn: GroupNode[]) => {
      this.dataSource.data = gn;
      this.selectNodeInTree();
    });
  }

  public selectSite(site_uuid: string) {
    if (site_uuid) this.onSiteSelected.emit(site_uuid);
  }
  
  public filterSites(value: string) {
    if (!value.length) delete this.listedSites;
    else {
      this.listedSites = value === '*' ?
      this.sites :
      this.sites.filter((s: Site) => this.removeAccentAndCase(s.site_name).indexOf(this.removeAccentAndCase(value)) !== -1);
    }
  }

  private removeAccentAndCase = (s: string): string => s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}