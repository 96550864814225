import { KeyValue } from "../models/key-value.model";

export const searchUuid = (s = ''): string[] => {
  const regex = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/;
  return s.match(regex) || [];
};

export const getKeyValueFromEnum = (enumeration: any): KeyValue[] =>
    Object.keys(enumeration).map((e) => ({ key: e, value: enumeration[e] }));

export const getComparisonSymbol = (key: string): string => {
  switch(key) {
    case 'less': return '<';
    case 'equal': return '=';
    case 'not_equal': return '!=';
    case 'greater': return '>';
  }
}