import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SpinnerService, LoginService } from '../services';
import { CookieService } from 'ngx-cookie-service';
import { apiUrl } from '@shared/URL';
import { MatDialog } from '@angular/material';
import { MessageComponent } from '@shared/components/dialogs/message/message.component';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private cookieService: CookieService,
    private dialog: MatDialog,
    private spinner: SpinnerService,
    private loginService: LoginService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(apiUrl.oustonne)) this.spinner.show();
    
    const request: HttpRequest<any> = req.clone({
      setHeaders: {
        'Authorization': `Bearer ${this.cookieService.get('token')}`
      }
    });

    return next.handle(request).pipe(
      tap((e: HttpEvent<any>) => {
        if (e instanceof HttpResponse) this.spinner.hide();
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          // this.showErrorDialog(error.message);
          console.error('catchError', request.url, error);
        } else if (error.status === 401) {
          this.loginService.logout();
        } else if (error.status >= 500) {
          // this.showErrorDialog('Erreur système');
          console.error('catchError', request.url, error);
        }
        this.spinner.hide(error.status);
        return throwError(error);
      })
    );
  }

  private showErrorDialog(message: string) {
    this.dialog.open(MessageComponent,
      {
        data: {message}
    });
  }
}
