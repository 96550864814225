import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { AuthenticatedGuardAdmin } from './guards/authenticated.guard-admin';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { AlarmsService, QuestionControlService, SpinnerService } from './services';
import { LoginService } from './services/login.service';
import { PasswordService } from './services/password.service';
import { SiteService } from './services/site.service';
import { DeviceService } from './services/device.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    AuthenticatedGuard,
    AuthenticatedGuardAdmin,
    LoginService,
    QuestionControlService,
    SpinnerService,
    PasswordService,
    SiteService,
    AlarmsService,
    DeviceService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ]
})
export class CoreModule {}
