import { Panel } from "./panel.model";
import { Task } from "./task.model";
import * as moment from 'moment';
import { Configuration } from "./configuration.model";
import { Activation } from "./activation.model";

export interface Site {
  site_uuid: string;
  site_name: string;
  site_location?: any | {
    type: string;
    coordinates: number[];
  };
  group_uuid?: string;
  group_name?: string;
  group_father?: string;
  user_uuid?: string;
  panels?: Panel[];
  tasks?: Task[];
  configuration?: any;
  // custom
  lat?: number;
  lng?: number;
  hovered?: boolean;
  last_seen?: moment.Moment;
  configurations: Configuration[];
  activations: Activation[];
  status: string;
}

export interface LastSeen {
  site_uuid: string;
  max: moment.Moment;
}

export function setSiteConfigs(s: Site, confs: Configuration[]): Site {
  return Object.assign(s, {configurations: confs.filter((c: Configuration) => s.site_uuid === c.site_uuid)});
}

export function setSitePanels(s: Site): Site {
  s.panels = s && s.configuration && s.configuration.panels ? s.configuration.panels : [];
  delete s.configuration;
  s.panels.forEach((p: Panel) => {
    if (!p.src.startsWith('https://elumana.e-lum.io') && !p.src.startsWith('https://elumana.beta.e-lum.io')) console.log('Invalid panel url: ', s.site_name, p.src);
  });
  return s;
}

export function setSiteCoordinates(s: Site): Site {
  s.lng = s.site_location ? JSON.parse(s.site_location).coordinates[0] : undefined;
  s.lat = s.site_location ? JSON.parse(s.site_location).coordinates[1] : undefined;
  delete s.site_location;
  return s;
}