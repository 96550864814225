import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Group, GroupNode, LastSeen, setSiteCoordinates, setSitePanels, Site } from '@models';
import { apiUrl } from '@shared/URL';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

@Injectable()
export class SiteService {
  private sites: Site[];
  private groupNode: GroupNode[];
  private flatGroups: Group[];

  constructor(
    private http: HttpClient
  ) {}

  getSites(user_uuid: string): Observable<Site[]> {
    return this.http.get(`${apiUrl.sites}/${user_uuid}`).pipe(
      first(),
      map((sites: Site[]) => sites.sort((s1: Site, s2: Site) => s1.site_name.localeCompare(s2.site_name))),
      map((sites: Site[]) => sites.map((s: Site) => setSitePanels(s))),
      map((sites: Site[]) => sites.map((s: Site) => setSiteCoordinates(s))),
      tap((s: Site[]) => this.sites = s)
    );
  }

  getLastSeen(): Observable<LastSeen[]> {
    return this.http.get(apiUrl.lastSeen).pipe(
      first(),
      map((list: LastSeen[]) =>
        list.map((l: LastSeen) => ({site_uuid: l.site_uuid, max: moment(l.max).add(10, 'minute')}))
      )
    );
  }

  getGroupNode(user_uuid: string, group_uuid: string): Observable<GroupNode[]> {
    // if (this.groupNode) { return of(this.groupNode); }
    return this.http.get(`${apiUrl.groups}/${user_uuid}`).pipe(
      first(),
      map((groups: Group[]) => {
        this.flatGroups = groups.sort((g1: Group, g2: Group) => g1.group_name.localeCompare(g2.group_name));
        return this.buildTree(group_uuid);
      })
    );
  }

  private getNodeFromGroup = (g: Group): GroupNode => ({name: g.group_name, group_uuid: g.group_uuid});
  private getNodeFromSite = (s: Site): GroupNode => ({name: s.site_name, site_uuid: s.site_uuid});

  private buildTree(group_uuid: string): GroupNode[] {
    const root: GroupNode = this.getNodeFromGroup(this.flatGroups.find((g: Group) => g.group_uuid === group_uuid));
    this.createRecursiveGroups(root);
    this.groupNode = [root];
    return this.groupNode;
  }

  private createRecursiveGroups(node: GroupNode) {
    node.children = [
      // Add child groups
      ...this.flatGroups
        .filter((g: Group) => g.group_father === node.group_uuid)
        .map((g: Group) => this.getNodeFromGroup(g))
        .map((n: GroupNode) => {
          this.createRecursiveGroups(n);
          return n;
        }),
        // Add sites to the group
      ...this.sites
      .filter((s: Site) => s.group_uuid === node.group_uuid)
      .map((s: Site) => this.getNodeFromSite(s))
    ];
  }
}
