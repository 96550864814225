import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularMaterialModule } from './angular-material.module';
import { PopinComponent } from './components/dialogs/default/popin.component';
import { QuestionPopinComponent } from './components/dialogs/question/question-popin.component';
import { CriticityImagePipe } from './pipes/criticity-image.pipe';
import { DomSanitizePipe } from './pipes/dom-sanitize.pipe';
import { MomentPipe } from './pipes/moment.pipe';
import { DevicePopup } from './components/dialogs/device/device-popup.component';
import { ChangesPopup } from './components/dialogs/changes/changes.component';
import { MessageComponent } from './components/dialogs/message/message.component';
import { ActivationFilterPipe } from './pipes/activationFilter.pipe';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [
    TranslateModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DomSanitizePipe,
    CriticityImagePipe,
    MomentPipe,
    ActivationFilterPipe,
    PopinComponent
  ],
  declarations: [
    DomSanitizePipe,
    CriticityImagePipe,
    ActivationFilterPipe,
    MomentPipe,
    PopinComponent,
    QuestionPopinComponent,
    DevicePopup,
    ChangesPopup,
    MessageComponent
  ],
  entryComponents: [
    PopinComponent,
    QuestionPopinComponent,
    DevicePopup,
    ChangesPopup,
    MessageComponent
  ]
})
export class SharedModule {}
