export interface UserOptions {
  uuid?: string;
  username?: string;
  firstname?: string;
  lastname?: string;
  mail?: string;
  groupUuid?: string;
  isactivated?: boolean;
  companyUuid?: string;
  companyName?: string;
  groupName?: string;
  groupFather?: string;
}

export class OldUser {
  uuid: string;
  username: string;
  firstname: string;
  lastname: string;
  mail: string;
  groupUuid: string;
  isactivated: boolean;
  companyUuid: string;
  companyName: string;
  groupName: string;
  groupFather: string;

  constructor(options: UserOptions = {}) {
    this.uuid = options.uuid || '';
    this.username = options.username || '';
    this.firstname = options.firstname || '';
    this.lastname = options.lastname || '';
    this.mail = options.mail || '';
    this.groupUuid = options.groupUuid || '';
    this.isactivated = options.isactivated || false;
    this.companyUuid = options.companyUuid || '';
    this.companyName = options.companyName || '';
    this.groupName = options.groupName || '';
    this.groupFather = options.groupFather || '';
  }
}
