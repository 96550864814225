import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "@core/services/login.service";
import { PasswordService } from "app/core/services/password.service";
import { ReturnObject } from "app/shared/models/response.model";
import { PasswordValidation } from "app/shared/validators/match-password.validator";
import { User } from "@shared/models";

@Component({
  selector: 'app-password',
  styleUrls: ['./password.component.scss'],
  templateUrl: './password.component.html',
})
export class ChangePasswordComponent implements OnInit {
  public error: string;
  public hidePassword: boolean = true;
  public passwordForm: FormGroup;
  get current(): AbstractControl { return this.passwordForm.get('current'); }
  get password1(): AbstractControl { return this.passwordForm.get('password1'); }
  get password2(): AbstractControl { return this.passwordForm.get('password2'); }

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private passwordService: PasswordService
  ) {}

  ngOnInit() {
    this.passwordForm = this.fb.group({
      'current' : ['', Validators.required],
      'password1' : ['', [Validators.required, Validators.minLength(8)]],
      'password2': ['', Validators.required]
    }, {validator: PasswordValidation.MatchPassword});
  }

  public toggleHidePassword() {
    this.hidePassword =! this.hidePassword;
  }

  onSubmit() {
    this.loginService.fetchUser().subscribe((user: User) => {
      this.passwordService.changePassword(user.user_uuid, this.password1.value, this.current.value)
      .subscribe((r: ReturnObject) => {
        if (r.success) this.loginService.logout();
        else {
          this.error = r.error === 'error.badPassword' ?
          'Bad password' :
          'Unknown error ' + r.error;
        }
      });
    });
  }
} 
