import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { appUrl } from '@shared/URL';
import { PasswordService } from '@core/services/password.service';
import { ReturnObject } from '@shared/models';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class ForgotComponent implements OnInit {
  public form: FormGroup;
  public error: string;

  constructor(
    private passwordService: PasswordService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    this.setForm();
  }

  private setForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public forgot() {
    delete this.error;
    this.passwordService.forgotPassword(this.form.value.email, undefined)
    .subscribe((r: ReturnObject) => r.success ?
      this.router.navigate([appUrl.login]) :
      this.error = r.message
    );
  }
}
