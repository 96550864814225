import {Component, EventEmitter, Input, Output, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-question-popin',
  templateUrl: 'question-popin.component.html',
  styleUrls: ['./question-popin.component.scss'],
})
export class QuestionPopinComponent {
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private dialogRef: MatDialogRef<QuestionPopinComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  submit(isOk: boolean): void {
    this.dialogRef.close(isOk);
  }
}
