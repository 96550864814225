import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'moment'})
export class MomentPipe implements PipeTransform {
    transform(value: Date, format: string): string {
        if (!value) return '--';
        else if (!format) return moment(value).format('L');
        else if (format === 'fromNow') return moment(value).fromNow();
        else return moment(value).format(format);
    }
}