import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DeviceService } from '@core/services/device.service';
import { DevicePopup } from '@shared/components/dialogs/device/device-popup.component';
import { Device } from '@shared/models/device.model';
import { Sample, SampleMetadata } from '@shared/models/sample.model';

@Component({
  selector: 'app-remote',
  templateUrl: './remote.component.html',
  styleUrls: ['./remote.component.scss']
})
export class RemoteComponent implements OnDestroy {
  _site_uuid: string;
  @Input() set site_uuid(site_uuid: string) {
    this._site_uuid = site_uuid;
    if (this.eventSource) this.eventSource.close();
    this.devices = [];
    this.getStream();
  }
  get site_uuid(): string { return this._site_uuid }

  private eventSource: EventSource;
  private samples: Sample[];
  devices: Device[] = [];
  private comStatusId: string = '2bd0496a-7821-4bce-96df-f5462b6c86cc';

  constructor(
    private deviceService: DeviceService,
    private dialog: MatDialog
  ) {}
  
  ngOnDestroy() {
    if (this.eventSource) this.eventSource.close();
  }

  private getStream() {
    this.eventSource = this.deviceService.getStream(this.site_uuid);
    this.eventSource.addEventListener('samples-metadata-update', (event: MessageEvent) => {
      const metadatas: SampleMetadata[] = JSON.parse(event.data).samples_metadatas;
      // console.log('metadata', metadatas);
      this.setDevices(metadatas);
    });
    this.eventSource.addEventListener('samples-values-update', (event: MessageEvent) => {
      this.samples = JSON.parse(event.data).values;
      // console.log('samples', this.samples);
      if (this.devices.length) this.setSamples();
    });
  }

  private setDevices(metadatas: SampleMetadata[]) {
    this.devices = [];
    [...new Set(metadatas.map((m: SampleMetadata) => m.device_uuid))].forEach((device_uuid: string) => {
      const deviceMetadatas: SampleMetadata[] = metadatas.filter((m: SampleMetadata) => m.device_uuid === device_uuid && m.definition_uuid !== this.comStatusId);

      if (deviceMetadatas.length) {
        this.devices.push({
          device_uuid: deviceMetadatas[0].device_uuid,
          device_ref: deviceMetadatas[0].device_ref,
          device_custom_name: deviceMetadatas[0].device_custom_name,
          device_type: deviceMetadatas[0].device_type,
          connectedSampleId: metadatas.find((m: SampleMetadata) => m.device_uuid === device_uuid && m.definition_uuid === this.comStatusId).sample_id,
          samples: deviceMetadatas.map((m: SampleMetadata) => {
            return {
              sample_id: m.sample_id,
              type: this.getSampleType(m.value_type),
              name: m.long_name || m.short_name,
              unit: m.unit,
              min : m.range_min,
              max : m.range_max,
              editable: m.channel_access !== 'ro'
            }
          }).sort((s1: Sample, s2: Sample) => {
            if (s1.name.toLowerCase() > s2.name.toLowerCase()) return 1;
            if (s1.name.toLowerCase() < s2.name.toLowerCase()) return -1;
            return 0;
          })
        });
      }
    });
    if (this.samples) this.setSamples();
  }

  private setSamples() {
    this.devices.forEach((d: Device) => {
      d.connected = this.samples.find((s: Sample) => s.sample_id === d.connectedSampleId).value as boolean;
      d.samples.forEach((s: Sample) => {
        const sample: Sample = this.samples.find((s2: Sample) => s.sample_id === s2.sample_id);
        s.value = this.getSampleValue(sample);
      });
    });
  }

  private getSampleType(type: string): string {
    switch (type) {
      case 'bool': return 'bool';
      case 'double': return 'number';
      default: return 'text';
    }
  }

  private getSampleValue(s: Sample): number | string | boolean {
    if (s.type === 'ERROR') return '--';
    if (s.type === 'DOUBLE') return Math.round(s.value as number * 100) / 100;
    return s.value;
  }

  showDevice(device: Device) {
    this.dialog.open(DevicePopup, {
      autoFocus: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'devices-edition',
      data: {
        device,
        site_uuid: this.site_uuid
      }
    });
  }
}
