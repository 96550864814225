import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { apiUrl } from '@shared/URL';

@Injectable()
export class PasswordService {

  constructor(
    private http: HttpClient
  ) {}

  changePassword(user_uuid: string, newPassword: string, oldPassword: string): Observable<any> {
    return this.http.post(`${apiUrl.changePassword}/${user_uuid}`, { passwords : { newPassword, oldPassword }});
  }

  resetPassword(activation_uuid: string, password: string): Observable<any> {
    return this.http.post(apiUrl.setPassword, {activation_uuid, password});
  }

  forgotPassword(user_mail: string, whiteLabel: string): Observable<any> {
    return this.http.post(apiUrl.forgotPassword, {user_mail, whiteLabel});
  }
}
