import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AlarmsService } from '@core/services';

@Component({
  selector: 'app-alarms-history',
  templateUrl: './alarms-history.component.html',
  styleUrls: ['./alarms-history.component.scss']
})
export class AlarmsHistoryComponent {
  @Output() onChangeTime: EventEmitter<any> = new EventEmitter();
  sortArray: boolean[] = [false, false, false, false, false];
  criticities = new FormControl([]);

  constructor(
    public aS: AlarmsService
  ) {}

  sortByFrom() {
    this.sortArray = [!this.sortArray[0], false, false, false, false];
  }

  sortByTo() {
    this.sortArray = [false, !this.sortArray[1], false, false, false];
  }

  sortByDevice() {
    this.sortArray = [false, false, !this.sortArray[2], false, false];
  }

  sortByMessage() {
    this.sortArray = [false, false, false, !this.sortArray[3], false];
  }
  
  sortByCriticity() {
    this.sortArray = [false, false, false, false, !this.sortArray[4]];
  }
}