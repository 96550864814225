import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as whiteLabel from '../assets/whiteLabel/configs.json';

@Component({
  selector: 'app',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html'
})
export class App implements OnInit, OnDestroy {
  private route$: Subscription;
  private iconList: string[] = [
    'overview',
    'battery',
    'inverter',
    'alarm'
  ];

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.setRouteListener();
    this.setTabTitle();
    // const defaultLang: string = navigator.language.length === 2 ? `${navigator.language}-${navigator.language.toUpperCase()}` : navigator.language
    this.translateService.setDefaultLang('en');

    // Add all custom icons
    for (let icon of this.iconList) {
      this.addCustomIcon(icon);
    }
  }

  ngOnDestroy() {
    if (this.route$) this.route$.unsubscribe();
  }

  private setRouteListener() {
    this.route$ = this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd && (<any>window).gtag) {
        (<any>window).gtag('config', 'UA-109056985-2', { page_path: event.urlAfterRedirects });
      }
    });
  }

  private setTabTitle() {
    let title: string = 'Elum';
    if (location.pathname) {
      const path: string[] = location.hostname.split('.');
      if (path[0] === 'www') path.shift();
      if (path[0].toLowerCase() === 'nokia')
        // title = whiteLabel.default[path[0]].title_name;
        title = 'Nokia';
    }
    this.titleService.setTitle(`${title} | Dashboard`);
  }

  private addCustomIcon(name: string) {
    this.matIconRegistry.addSvgIcon(
      name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../assets/custom_icons/${name}.svg`
      )
    );
  }
}
