import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AlarmsService, LoginService } from '@core/services';
import { Channel, Configuration, User } from '@models';
import { EditAlarmComponent } from './edit/edit-alarm.component';

@Component({
  selector: 'app-alarms-settings',
  templateUrl: './alarms-settings.component.html',
  styleUrls: ['./alarms-settings.component.scss']
})
export class AlarmsSettingsComponent implements OnInit {
  @Output() onDeleteConfig: EventEmitter<string> = new EventEmitter();
  @Output() onAddOrEditConfig: EventEmitter<{conf: Configuration, old?: Configuration}> = new EventEmitter();
  @Input() channels: Channel[];

  public user: User;
  private fb: FormBuilder = new FormBuilder();
  private sortArray: boolean[] = [false, false, false, false];

  constructor(
    private loginService: LoginService,
    public aS: AlarmsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loginService.fetchUser().subscribe((user: User) => {
      this.user = user;
    });
  }

  public changeNotif(c: Configuration) {
    c.isNotified ?
      this.aS.removeUserFromMailingList(c.alarm_uuid, this.user.user_uuid).subscribe(
        (response: HttpResponse<any>) => c.isNotified = response.status === 404)
    :
      this.aS.addUserToMailingList(c.alarm_uuid, this.user.user_uuid).subscribe(
        (response: HttpResponse<any>) => c.isNotified = response.status === 201);
  }

  public editConfig(old?: Configuration) {
    if (this.user.isadmin || this.user.admin) {
      this.dialog.open(EditAlarmComponent, {
        data: {
          title: 'sites.popin.titleEdit',
          cancelText: 'sites.popin.cancel',
          okText: 'sites.popin.ok',
          channels: this.channels,
          form: this.fb.group({
            'criticity': [old ? old.criticity : '', Validators.required],
            'comparison': [old ? old.comparison : '', Validators.required],
            'reference': [old ? old.reference : '', Validators.required],
            'channel_uuid': [old ? old.channel_uuid : '', Validators.required],
            'alarm_custom_name': [old ? old.alarm_custom_name : '', Validators.required],
            'message': [old ? old.message : '', Validators.required]
          })
        }
      }).afterClosed().subscribe((conf: Configuration) => {
        if (conf) {
          if (old) {
            conf.alarm_uuid = old.alarm_uuid;
            this.onAddOrEditConfig.emit({conf, old});
          } else {
            this.onAddOrEditConfig.emit({conf});
          }
        }
      });
    }
  }
 
  sortByMessage() {
    this.sortArray[1] ?
      this.aS.site.configurations.reverse() :
      this.aS.site.configurations.sort((c1: Configuration, c2: Configuration) => {
        const name1 = c1.message.toLowerCase(), name2 = c2.message.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
      });
    this.sortArray = [false, !this.sortArray[1], false, false];
  }

  sortByDevice() {
    this.sortArray[2] ?
      this.aS.site.configurations.reverse() :
      this.aS.site.configurations.sort((c1: Configuration, c2: Configuration) => {
        const name1 = c1.channel.device_custom_name.toLowerCase(), name2 = c2.channel.device_custom_name.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
      });
    this.sortArray = [false, false, !this.sortArray[2], false];
  }

  sortByCriticity() {
    this.sortArray[3] ?
      this.aS.site.configurations.reverse() :
      this.aS.site.configurations.sort((c1: Configuration, c2: Configuration) => {
        const name1 = c1.criticity.toLowerCase(), name2 = c2.criticity.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
      });
    this.sortArray = [false, false, false, !this.sortArray[3]];
  }
}
