import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ForgotComponent } from '@core/components/forgot/forgot.component';
import { LoginComponent } from '@core/components/login/login.component';
import { ResetComponent } from '@core/components/reset/reset.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { App } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from './view/components/header/header.component';
import { HomeComponent } from './view/components/home/home.component';
import { ChangePasswordComponent } from './view/components/password/password.component';
import { AlarmsHistoryComponent } from './view/components/site/alarms-history/alarms-history.component';
import { AlarmsSettingsComponent } from './view/components/site/alarms-settings/alarms-settings.component';
import { EditAlarmComponent } from './view/components/site/alarms-settings/edit/edit-alarm.component';
import { FsDashboardComponent } from './view/components/site/fullscreen/fs-dashboard.component';
import { SiteComponent } from './view/components/site/site.component';
import { TreeComponent } from './view/components/site/tree/tree.component';
import { RemoteComponent } from './view/components/site/remote/remote.component';

@NgModule({
  bootstrap: [App],
  declarations: [
    App,
    HeaderComponent,
    LoginComponent,
    ResetComponent,
    ForgotComponent,
    HomeComponent,
    ChangePasswordComponent,
    SiteComponent,
    AlarmsHistoryComponent,
    AlarmsSettingsComponent,
    EditAlarmComponent,
    FsDashboardComponent,
    RemoteComponent,
    TreeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/', '.json'),
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({apiKey: environment.AGM_KEY
    }),
    AgmJsMarkerClustererModule,
    AppRoutingModule
  ],
  entryComponents: [EditAlarmComponent, FsDashboardComponent],
  providers: [CookieService]
})

export class AppModule {}
