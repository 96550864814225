import { Injectable } from '@angular/core';

@Injectable()
export class SpinnerService {
  private loader: HTMLElement;
  private numberActiveRequests: number = 0;

  constructor() {
    this.loader = document.getElementById('loader');
  }

  public show() {
    this.numberActiveRequests++;
    this.loader.style['display'] = 'flex';
  }
  
  public hide(errorCode: number = 0) {
    this.numberActiveRequests = errorCode === 401 ? 0 : this.numberActiveRequests - 1;
    if (this.numberActiveRequests <= 0) this.loader.style['display'] = 'none';
  }
}
