import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DeviceService } from '@core/services/device.service';
import { Activation, Channel, Configuration, Panel, Site, User } from '@models';
import { TranslateService } from '@ngx-translate/core';
import { AlarmsService, LoginService, SiteService } from '@services';
import { getComparisonSymbol } from '@shared/libs/commonLib';
import { appUrl } from '@shared/URL';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { FsDashboardComponent } from './fullscreen/fs-dashboard.component';

@Component({
  selector: 'app-site',
  styleUrls: ['./site.component.scss'],
  templateUrl: './site.component.html',
})
export class SiteComponent implements OnInit {
  public user: User;
  public sites: Site[];
  public userHaveAccessToRemote: boolean = false;
  public channels: Channel[];

  constructor(
    private deviceService: DeviceService,
    private siteService: SiteService,
    private loginService: LoginService,
    public aS: AlarmsService,
    public route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.onRouteChange();
    this.loginService.fetchUser().subscribe((user: User) => {
      if (user) {
        // todo: to find a better solution to avoid ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(()=> {
          this.user = user;
          this.siteService.getSites(user.user_uuid).subscribe((sites: Site[]) => {
            if (sites) {
              this.sites = sites;
              this.setSiteWithId(this.route.snapshot.queryParams.id);
            }
          });
        }, 0);
      }
    });
  }

  private setSiteWithId(site_uuid: string) {
    if (site_uuid) {
      this.resetData();
      this.aS.site = this.sites.find((s: Site) => s.site_uuid === site_uuid);
      this.fetchDataBySiteUUID(site_uuid);
    } else if (this.sites) {
      this.aS.site = this.sites[0];
      this.selectSite(this.aS.site.site_uuid);
    }
  }

  public selectSite(site_uuid: string) {
    this.router.navigate([appUrl.site], {queryParams: {id: site_uuid}});
  }

  private onRouteChange() {
    this.route.queryParams.subscribe((p: Params) => {
      if (this.sites) this.setSiteWithId(p.id);
    });
  }

  private resetData() {
    this.userHaveAccessToRemote = false;
    this.channels = [];
    this.aS.site = undefined;
  }

  private fetchDataBySiteUUID(site_uuid: string) {
    this.deviceService.haveAccess(site_uuid).subscribe(() => this.userHaveAccessToRemote = true);
    forkJoin(
      this.aS.getConfigurationsBySiteUUID(site_uuid),
      this.aS.getChannelsBySiteUUID(site_uuid),
      this.aS.getActivationsBySiteUUID(site_uuid)
    ).subscribe((res: [Configuration[], Channel[], Activation[]]) => {
      this.channels = res[1];
      this.aS.site.configurations = res[0].map((c: Configuration) => {
        c.channel = this.channels.find((ch: Channel) => ch.channel_uuid === c.channel_uuid)
        c.condition = `${c.channel.short_name} ${getComparisonSymbol(c.comparison)} ${c.reference} ${c.channel.unit || ''}`;
        return c;
      });
      this.aS.site.activations = this.fillActivations(res[2]);
      this.setNotifications();
    });
  }

  private setNotifications() {
    this.aS.site.configurations.forEach((c: Configuration) => {
      this.aS.isUserInMailingList(c.alarm_uuid, this.user.user_uuid)
        .subscribe(
          () => c.isNotified = true,
          (err: HttpErrorResponse) => { if (err.status === 404) c.isNotified = false; }
        )
    });
  }

  getAlarmsByDate() {
    this.aS.site.activations = [];
    this.aS.getActivationsBySiteUUID(this.aS.site.site_uuid).subscribe((a: Activation[]) => {
      this.aS.site.activations = this.fillActivations(a);
    })
  }

  private fillActivations(activations: Activation[]): Activation[] {
    return activations.map((a: Activation) => {
      const matchingConfiguration = this.aS.site.configurations.find((c: Configuration) => c.alarm_uuid === a.alarm_uuid);
      a.alarm_custom_name = matchingConfiguration.alarm_custom_name;
      a.device_custom_name = matchingConfiguration.channel.device_custom_name;
      a.message = matchingConfiguration.message;
      a.criticity = matchingConfiguration.criticity;
      a.isNew = !a.to && moment(a.from).isAfter(moment().subtract(1, 'day'))
      return a;
    }).sort((a1: Activation, a2: Activation) => {
      if (!a1.to) return -1;
      if (!a2.to) return 1;
      return new Date(a2.from).getTime() - new Date(a1.from).getTime();
    });
  }

  onAddOrEditConfig({conf, old}: {conf: Configuration, old: Configuration}) {
    conf.site_uuid = this.aS.site.site_uuid;
    this.aS.putConfiguration(conf).subscribe(() => {
      conf.channel = this.channels.find((ch: Channel) => ch.channel_uuid === conf.channel_uuid);
      conf.condition = `${conf.channel.short_name} ${getComparisonSymbol(conf.comparison)} ${conf.reference} ${conf.channel.unit || ''}`;
      if (old) {
        this.aS.site.configurations.forEach((c: Configuration, i: number) => {
          if (old.alarm_uuid === c.alarm_uuid) this.aS.site.configurations[i] = conf;
        });
        this.aS.site.activations = this.fillActivations(this.aS.site.activations);
      } else
        this.aS.site.configurations.push(conf);
    });
  }
  
  onDeleteConfig(alarm_uuid: string) {
    this.translate.get('sites.popin.confirmDelete').subscribe((res: string) => {
      if (confirm(res)) {
        this.aS.deleteConfiguration(alarm_uuid).subscribe(() => {
          this.aS.site.configurations.forEach((c: Configuration, i: number) => {
            if(c.alarm_uuid === alarm_uuid) this.aS.site.configurations.splice(i, 1);
          });
        });
      }
    });
  }

  setFullScreenDashboard(panel: Panel) {
    this.dialog.open(FsDashboardComponent, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        panelClass: 'no-padding-dialog',
        data: panel,
      })
      .afterClosed()
      .subscribe();
  }
}
