import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Sample } from '@shared/models/sample.model';
import { apiUrl } from '@shared/URL';
import { Observable } from 'rxjs';

@Injectable()
export class DeviceService {
  constructor(
    private http: HttpClient
  ) {}

  haveAccess(site_uuid: string): Observable<any> {
    return this.http.get(`${apiUrl.oustonne}${site_uuid}`);
  }
  
  getStream(site_uuid: string): EventSource {
    return new EventSource(`${apiUrl.oustonne}${site_uuid}${apiUrl.read}`);
  }

  setSampleValues(site_uuid: string, samples: Sample[]): Observable<any> {
    return this.http.put(`${apiUrl.oustonne}${site_uuid}${apiUrl.write}`, samples);
  }
}
