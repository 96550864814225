import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@core/guards/authenticated.guard';
import { HomeComponent } from './view/components/home/home.component';
import { ChangePasswordComponent } from './view/components/password/password.component';
import { LoginComponent } from '@core/components/login/login.component';
import { AuthenticatedGuardAdmin } from '@core/guards/authenticated.guard-admin';
import { SiteComponent } from './view/components/site/site.component';
import { ForgotComponent } from '@core/components/forgot/forgot.component';
import { ResetComponent } from '@core/components/reset/reset.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'reset/:activation_uuid', component: ResetComponent },
  {
    path: 'admin',
    canActivate: [AuthenticatedGuardAdmin],
    loadChildren: './view/modules/admin/admin.module#AdminModule'
  },
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  {
    path: '',
    canActivate: [AuthenticatedGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'site', component: SiteComponent },
      { path: 'change-password', component: ChangePasswordComponent }
    ]
  },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
