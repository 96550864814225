import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-popin',
  templateUrl: 'popin.component.html',
  styleUrls: ['./popin.component.scss'],
})
export class PopinComponent {
  @Input() data: any;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  close(): void { this.onClose.emit(); }
  submit(): void { this.onClose.emit(true); }
}
