import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation } from '@shared/validators/match-password.validator';
import { PasswordService } from '@core/services/password.service';
import { appUrl } from '@shared/URL';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class ResetComponent implements OnInit {
  public form: FormGroup;
  public error: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private passwordService: PasswordService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.setForm();
  }

  private setForm() {
    this.form = this.fb.group({
      'password1' : ['', [Validators.required, Validators.minLength(8)]],
      'password2': ['', Validators.required]
    }, {validator: PasswordValidation.MatchPassword});
  }

  public reset() {
    delete this.error;
    this.passwordService.resetPassword(
      this.route.snapshot.paramMap.get('activation_uuid'),
      this.form.value.password1
    ).subscribe(
      () => this.router.navigateByUrl(appUrl.login),
      (e: string) => this.error = e
    );
  }
}
