import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ReturnObject, User } from '@shared/models';
import { apiUrl, appUrl } from '@shared/URL';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class LoginService {
  public userArrived: EventEmitter<User> = new EventEmitter();
  private user: User;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private translateService: TranslateService,
    private router: Router
  ) {}

  isUserLogged = (): boolean => {
    try {
      const expirationDate: number = JSON.parse(atob(this.cookieService.get('token').split('.')[1])).exp * 1000;
      return expirationDate > new Date().getTime();
    } catch (e) {
      return false;
    }
  }
  
  login(user: any) {
    return this.http.post(apiUrl.userLogin, user).pipe(
      tap((response: ReturnObject) => {
        if (response && response.success) {
          this.cookieService.deleteAll();
          // Set token
          this.cookieService.set('token', response.jwt);
          // Grafana cookie
          this.cookieService.set('grafana_sess', response.grafana_session, undefined, '/', '.e-lum.io');
          this.user = response.user;
          this.setLang(this.user);
          this.userArrived.emit(this.user);
        } else if (!response.success) {
          throw(response.error);
        }
      })
    );
  }

  fetchUser(): Observable<User> {
    if (this.user) return of(this.user);
    return this.http.post(apiUrl.user, {token: this.cookieService.get('token')}).pipe(
      map((response: ReturnObject) => response.user),
      tap((user: User) => {
        this.user = user;
        this.setLang(user);
        this.userArrived.emit(user)
      })
    );
  }

  setLang(user: User) {
    const lang: string = user.user_front_config.ui_lang;
    moment.locale(lang);
    this.translateService.use(lang);
  }

  logout(returnUrl?: string) {
    this.cookieService.deleteAll();
    returnUrl ?
      this.router.navigate([appUrl.login], { queryParams: { returnUrl }}) :
      this.router.navigate([appUrl.login]);
  } 
}
