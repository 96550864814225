import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MatSelectChange, MAT_DIALOG_DATA } from '@angular/material';
import { getKeyValueFromEnum } from 'app/shared/libs/commonLib';
import { Channel } from 'app/shared/models';
import { EAlarmComparison, EAlarmCriticity } from 'app/shared/models/enumerations';
import { KeyValue } from 'app/shared/models/key-value.model';

@Component({
  selector: 'app-edit-alarm',
  templateUrl: 'edit-alarm.component.html',
  styleUrls: ['./edit-alarm.component.scss']
})
export class EditAlarmComponent {
  // Lists of enumerations
  public criticities: KeyValue[] = getKeyValueFromEnum(EAlarmCriticity);
  public comparisons: KeyValue[] = getKeyValueFromEnum(EAlarmComparison);
  public channelUnit: string = '';
  public channel: Channel; // Device
  public channels: Channel[] = []; // Device list
  public filteredChannels: Channel[]; // List of channel_uuid

  get form(): FormGroup { return this.data.form; }

  constructor(
    private dialogRef: MatDialogRef<EditAlarmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { this.setData(); }

  private setData() {
    this.data.channels.forEach((c: Channel) => {
      if (!this.channels.find((c2: Channel) => c2.device_uuid === c.device_uuid)) this.channels.push(c);
    });
    if (this.form.value.alarm_custom_name) { // Edition
      this.channel = this.channels.find((c: Channel) =>
        c.device_uuid === this.data.channels.find((c2: Channel) => c2.channel_uuid === this.form.value.channel_uuid).device_uuid
      );
      this.filteredChannels =  this.data.channels.filter((c: Channel) => c.device_uuid === this.channel.device_uuid);
      this.channelUnit = this.filteredChannels.find((c: Channel) => c.channel_uuid === this.form.value.channel_uuid).unit;
    }
  }

  onDeviceChange() {
    this.filteredChannels = this.data.channels.filter((c: Channel) => c.device_uuid === this.channel.device_uuid);
    // Reset values linked to the previous channel
    this.channelUnit = '';
    this.form.controls.channel_uuid.reset();
    this.form.controls.reference.reset();
  }

  onChannelChange(change: MatSelectChange) {
    this.channelUnit = this.filteredChannels.find((c: Channel) => c.channel_uuid === change.value).unit;
  }

  onClose(isOk: boolean): void {
    this.dialogRef.close(isOk ? this.form.getRawValue() : undefined);
  }
}
