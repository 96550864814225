import { environment } from "environments/environment";

export class apiUrl {
  // External URLS
  public static base: string = environment.API_URL;
  public static gyrophare: string = apiUrl.base + '/gyrophare/v1';
  public static archive: string = apiUrl.base + '/archive/v1';
  public static configurations: string = apiUrl.gyrophare + '/configurations';
  public static activations: string = apiUrl.gyrophare + '/activations';
  public static channels: string = apiUrl.archive + '/channels';
  public static metadatas: string = apiUrl.base + '/operator/metadatas';
  public static samples: string = apiUrl.base + '/operator/last_samples';
  public static mailing: string = 'mailing_list';
  
  public static oustonne: string = apiUrl.base + '/oustonne/site/';
  public static read: string = '/operator/live';
  public static write: string = '/operator/write';

  // Backend URLS
  public static backend: string = environment.BACKEND_URL;
  public static sites: string = apiUrl.backend + '/sites';
  public static lastSeen: string = apiUrl.sites + '/last_seen';
  public static groups: string = apiUrl.backend + '/groups';
  public static changePassword: string = apiUrl.backend + '/change_password';
  public static userLogin: string = apiUrl.backend + '/auth/login';
  public static user: string = apiUrl.backend + '/auth/user';
  public static setPassword: string = apiUrl.backend + '/auth/setpwd';
  public static forgotPassword: string = apiUrl.backend + '/auth/forgot_pwd';
}

export class appUrl {
  public static root: string = '/';
  public static home: string = '/home';
  public static login: string = '/login';
  public static reset: string = '/reset';
  public static site: string = '/site';
  public static sites: string = '/sites';
  public static admin: string = '/admin';
  public static password: string = '/change-password';
}
